import { AppFCC } from '@types';
import { Link, LinkExternal } from '@managers/links';
import { FooterContentDividerStyled, FooterContentLeftColStyled, FooterContentLinksStyled, FooterContentRightColStyled, FooterContentStyled, FooterLinkContentDomainStyled, FooterLinkContentStyled } from './styled';
import { FooterContentMobileProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const FooterContentMobile: AppFCC<FooterContentMobileProps> = props => {
  const {
    userAgreementLinkProps,
    privacyPolicyLinkProps,
    tariffsLinkProps,
    emailLinkProps,
    contactsLinkProps,
    copyrightLinkProps,
    domain
  } = props;
  return _jsxs(FooterContentStyled, {
    children: [_jsxs(FooterContentLeftColStyled, {
      children: [_jsx(LinkExternal, {
        href: userAgreementLinkProps.href,
        children: _jsx(FooterLinkContentStyled, {
          children: userAgreementLinkProps.children
        })
      }), _jsx(LinkExternal, {
        href: privacyPolicyLinkProps.href,
        children: _jsx(FooterLinkContentStyled, {
          children: privacyPolicyLinkProps.children
        })
      })]
    }), _jsxs(FooterContentRightColStyled, {
      children: [_jsxs(FooterContentLinksStyled, {
        children: [_jsx(Link, {
          routeDescriptor: contactsLinkProps.routeDescriptor,
          children: _jsx(FooterLinkContentStyled, {
            children: contactsLinkProps.children
          })
        }), _jsx(FooterContentDividerStyled, {
          children: "|"
        }), _jsx(LinkExternal, {
          href: emailLinkProps.href,
          children: _jsx(FooterLinkContentStyled, {
            children: emailLinkProps.children
          })
        })]
      }), _jsxs(FooterContentLinksStyled, {
        children: [_jsx(Link, {
          routeDescriptor: tariffsLinkProps.routeDescriptor,
          children: _jsx(FooterLinkContentStyled, {
            children: tariffsLinkProps.children
          })
        }), _jsx(FooterContentDividerStyled, {
          children: "|"
        }), _jsx(Link, {
          routeDescriptor: copyrightLinkProps.routeDescriptor,
          children: _jsxs(FooterLinkContentStyled, {
            children: ["\xA9\xA0", copyrightLinkProps.copyright, "\xA0", _jsx(FooterLinkContentDomainStyled, {
              children: domain
            })]
          })
        })]
      })]
    })]
  });
};