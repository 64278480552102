import { AppFCC } from '@types';
import { Link, LinkExternal } from '@managers/links';
import { FooterContentDividerStyled, FooterContentLinksStyled, FooterContentRowStyled, FooterContentStyled, FooterLinkContentDomainStyled, FooterLinkContentStyled } from './styled';
import { FooterContentTabletProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const FooterContentTablet: AppFCC<FooterContentTabletProps> = props => {
  const {
    userAgreementLinkProps,
    privacyPolicyLinkProps,
    tariffsLinkProps,
    emailLinkProps,
    contactsLinkProps,
    copyrightLinkProps,
    domain
  } = props;
  return _jsxs(FooterContentStyled, {
    children: [_jsxs(Link, {
      routeDescriptor: copyrightLinkProps.routeDescriptor,
      children: ["\xA9\xA0", copyrightLinkProps.copyright, "\xA0", _jsx(FooterLinkContentDomainStyled, {
        children: domain
      })]
    }), _jsxs(FooterContentRowStyled, {
      children: [_jsx(LinkExternal, {
        href: userAgreementLinkProps.href,
        children: _jsx(FooterLinkContentStyled, {
          children: userAgreementLinkProps.children
        })
      }), _jsx(LinkExternal, {
        href: privacyPolicyLinkProps.href,
        children: _jsx(FooterLinkContentStyled, {
          children: privacyPolicyLinkProps.children
        })
      }), _jsx(Link, {
        routeDescriptor: tariffsLinkProps.routeDescriptor,
        children: _jsx(FooterLinkContentStyled, {
          children: tariffsLinkProps.children
        })
      }), _jsx(FooterContentLinksStyled, {
        children: _jsxs(FooterLinkContentStyled, {
          children: [_jsx(Link, {
            routeDescriptor: contactsLinkProps.routeDescriptor,
            children: contactsLinkProps.children
          }), _jsx(FooterContentDividerStyled, {
            children: "|"
          }), _jsx(LinkExternal, {
            href: emailLinkProps.href,
            children: emailLinkProps.children
          })]
        })
      })]
    })]
  });
};