import { AppFCC } from '@types';
import { Link, LinkExternal } from '@managers/links';
import { FooterContentDividerStyled, FooterContentLinksStyled, FooterContentRowStyled, FooterContentStyled, FooterCopyrightStyled, FooterLinkContentStyled, FooterLogoLinkStyled, FooterLogoStyled } from './styled';
import { FooterContentDesktopProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const FooterContentDesktop: AppFCC<FooterContentDesktopProps> = props => {
  const {
    logoLinkRouteDescriptor,
    copyrightLinkProps,
    userAgreementLinkProps,
    privacyPolicyLinkProps,
    tariffsLinkProps,
    emailLinkProps,
    contactsLinkProps,
    domain
  } = props;
  return _jsxs(FooterContentStyled, {
    children: [_jsx(FooterLogoLinkStyled, {
      routeDescriptor: logoLinkRouteDescriptor,
      children: _jsx(FooterLogoStyled //
      , {
        src: "/images/logo-footer.svg",
        alt: domain,
        loaderDisabled: true
      })
    }), _jsxs(FooterContentRowStyled, {
      children: [_jsx(LinkExternal, {
        href: userAgreementLinkProps.href,
        children: _jsx(FooterLinkContentStyled, {
          children: userAgreementLinkProps.children
        })
      }), _jsx(LinkExternal, {
        href: privacyPolicyLinkProps.href,
        children: _jsx(FooterLinkContentStyled, {
          children: privacyPolicyLinkProps.children
        })
      }), _jsx(Link, {
        routeDescriptor: tariffsLinkProps.routeDescriptor,
        children: _jsx(FooterLinkContentStyled, {
          children: tariffsLinkProps.children
        })
      }), _jsxs(FooterContentLinksStyled, {
        children: [_jsx(Link, {
          routeDescriptor: contactsLinkProps.routeDescriptor,
          children: _jsx(FooterLinkContentStyled, {
            children: contactsLinkProps.children
          })
        }), _jsx(FooterContentDividerStyled, {
          children: "|"
        }), _jsx(LinkExternal, {
          href: emailLinkProps.href,
          children: _jsx(FooterLinkContentStyled, {
            children: emailLinkProps.children
          })
        })]
      })]
    }), _jsxs(FooterCopyrightStyled, {
      children: ["\xA9\xA0", copyrightLinkProps.copyright]
    })]
  });
};