import { AppFCC } from '@types';
import { FooterAddress } from '@kit/footer/footer-address';
import { FooterExtraLinks } from '@kit/footer/footer-extra';
import { FooterContentDesktop } from './footer-content/desktop';
import { FooterContentMobile } from './footer-content/mobile';
import { FooterContentTablet } from './footer-content/tablet';
import { FooterStyled, footerWidgetAddressClassName } from './styled';
import { FooterProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const Footer: AppFCC<FooterProps> = props => {
  return _jsxs(FooterStyled, {
    children: [_jsx(FooterContentMobile, {
      ...props
    }), _jsx(FooterContentTablet, {
      ...props
    }), _jsx(FooterContentDesktop, {
      ...props
    }), _jsx(FooterAddress, {
      className: footerWidgetAddressClassName
    }), _jsx(FooterExtraLinks, {
      ...props
    })]
  });
};